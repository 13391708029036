



















































































































































































import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {Instructor} from '~/components/data-class/data-class'
import {InvoiceData, InvoiceStatus} from '~/components/invoice/invoice-data'
import StatusDot from '~/components/invoice/status-dot.vue'
import PaginationMixin from '~/mixins/pagination-mixin'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import {numberWithComma, tenToDot} from '~/utils/misc'
import {createRequest, serverBaseURL} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {StatusDot},
    metaInfo() {
        return {
            title: 'Invoice List'
        }
    }
})
export default class InvoiceList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = AdminPermission.Invoice
    step: number = 20
    keyword: string = ''

    queryDef = [
        {localVar: 'iMid', queryStr: 'tutor', type: QueryType.String},
        {localVar: 'status', type: QueryType.Number},
    ]

    tableData: InvoiceData[] = []

    // filter
    periodOp: string[] = []
    filterPeriod: string | null = null
    instructor: Instructor | null = null
    iMid: string = ''
    status: InvoiceStatus | '' = ''
    InvoiceStatus = InvoiceStatus

    // xlsx
    showGenXlsx = false
    gLoading = false
    xlsFrom: string | null = null
    xlsTo: string | null = null

    @Watch('instructor')
    iChanged() {
        this.toPage(1)
    }

    async updateData() {
        const q: any = {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step
        }

        if (this.filterPeriod) {
            const [year, month] = this.filterPeriod.split('-').map(Number)
            q.year = year
            q.month = month
        }

        if (this.iMid) {
            q.member_id = this.iMid
        }

        if (typeof this.status === 'number') {
            q.status = this.status
        }

        const res = await createRequest('/invoice-data-list/admin', 'get', q).send()
        this.totalCount = res.data.count
        this.tableData = res.data.data

        await this.setQuery()
    }

    async created() {
        this.parseQuery()
        await this.setQuery(false)
        await this.updateData()

        const res = await createRequest('/invoice/month-options', 'get').send()
        this.periodOp = res.data.months.map((m) => `${m.year}-${m.month < 10 ? 0 : ''}${m.month}`)
    }

    period(data: string | InvoiceData) {
        if (typeof data === 'string') {
            const [year, month] = data.split('-').map(Number)
            return this._period(year, month)
        } else {
            return this._period(data.year, data.month)
        }
    }

    _period(year: number, month: number) {
        const m = moment({month: month - 1, year})
        return m.format('MMM YYYY')
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    statusStr(status: InvoiceStatus) {
        return InvoiceStatus[status]
    }

    clickInstructor(data: InvoiceData) {
        this.iMid = (this.iMid === data.member_id) ? '' : data.member_id
    }

    clickPeriod(data: InvoiceData) {
        const found = this.periodOp.find(op => op === `${data.year}-${data.month}`)
        this.filterPeriod = (this.filterPeriod === found) ? null : found!
        this.toPage(1)
    }

    async download(data: InvoiceData) {
        const dl = this.$refs.dummyLink as unknown as HTMLAnchorElement
        dl.href = `${process.env.VUE_APP_DOMAIN_API}/invoice/${data._id}/download` + '?auth=' + await this.$auth.getAccessToken()
        dl.click()
    }

    async downloadCsv() {
        const fileLink = document.createElement('a')
        fileLink.href = serverBaseURL + '/invoice-data-list/invoices.csv?auth=' + await this.$auth.getAccessToken()
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }

    async generateXlsx() {
        const [f, t] = [this.xlsFrom, this.xlsTo].filter(m => !!m).sort()
        const ms = this.periodOp.filter(m => (f ? m >= f : true) && (t ? m <= t : true))

        this.gLoading = true
        const res = await createRequest('/dashboard/account-excel', 'get', {months: ms.join()}).send()

        const fileLink = document.createElement('a')
        fileLink.href = res.data.url
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
        this.gLoading = false
        this.showGenXlsx
    }
}
